import * as ACTIONS from "./actionTypes";

const initialState = {
  extradeclarations: {
    list: [],
    total: 0,
  },
};

function extradeclarationsReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.GET_EXTRADECLARATIONS_SUCCESS: {
      return {
        ...state,
        ...{ extradeclarations: payload },
      };
    }

    default:
      return state;
  }
}

export default extradeclarationsReducer;
