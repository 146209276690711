export const GET_EXTRADECLARATIONS_REQUEST = "GET_EXTRADECLARATIONS_REQUEST";
export const GET_EXTRADECLARATIONS_SUCCESS = "GET_EXTRADECLARATIONS_SUCCESS";

export const SHOW_EXTRADECLARATION_REQUEST = "SHOW_EXTRADECLARATION_REQUEST";
export const SHOW_EXTRADECLARATION_SUCCESS = "SHOW_EXTRADECLARATION_SUCCESS";

export const POST_EXTRADECLARATION_REQUEST = "POST_EXTRADECLARATION_REQUEST";
export const POST_EXTRADECLARATION_SUCCESS = "POST_EXTRADECLARATION_SUCCESS";

export const PUT_EXTRADECLARATION_REQUEST = "PUT_EXTRADECLARATION_REQUEST";
export const PUT_EXTRADECLARATION_SUCCESS = "PUT_EXTRADECLARATION_SUCCESS";

export const DELETE_EXTRADECLARATION_REQUEST = "DELETE_EXTRADECLARATION_REQUEST";
export const DELETE_EXTRADECLARATION_SUCCESS = "DELETE_EXTRADECLARATION_SUCCESS";
