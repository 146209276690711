export const GET_VENSUBSCRIPTIONS_REQUEST = "GET_VENSUBSCRIPTIONS_REQUEST";
export const GET_VENSUBSCRIPTIONS_SUCCESS = "GET_VENSUBSCRIPTIONS_SUCCESS";

export const SHOW_VENSUBSCRIPTION_REQUEST = "SHOW_VENSUBSCRIPTION_REQUEST";
export const SHOW_VENSUBSCRIPTION_SUCCESS = "SHOW_VENSUBSCRIPTION_SUCCESS";

export const POST_VENSUBSCRIPTION_REQUEST = "POST_VENSUBSCRIPTION_REQUEST";
export const POST_VENSUBSCRIPTION_SUCCESS = "POST_VENSUBSCRIPTION_SUCCESS";

export const PUT_VENSUBSCRIPTION_REQUEST = "PUT_VENSUBSCRIPTION_REQUEST";
export const PUT_VENSUBSCRIPTION_SUCCESS = "PUT_VENSUBSCRIPTION_SUCCESS";

export const DELETE_VENSUBSCRIPTION_REQUEST = "DELETE_VENSUBSCRIPTION_REQUEST";
export const DELETE_VENSUBSCRIPTION_SUCCESS = "DELETE_VENSUBSCRIPTION_SUCCESS";

export const VEN_DOWNLOAD_SUBSCRIPTIONS_EXCEL = "VEN_DOWNLOAD_SUBSCRIPTIONS_EXCEL";
export const VEN_DOWNLOAD_SUBSCRIPTIONS_EXCEL_SUCCESS = "VEN_DOWNLOAD_SUBSCRIPTIONS_EXCEL_SUCCESS";
